import { stripRichTextWrapperTag } from '@/utils/html';
import { adapter as imageAdapter } from '@/components/cloud-image/utils';
import { routerLinks } from '~/utils/fetch-links';

const getItems = async (
  moduleData,
  moduleId,
  $prismic,
  $enhancedLinkSerializer
) => {
  let needfetch = false;
  const listItems = moduleData?.advantage_entries.map((item) => {
    const linkData = item.advantage_entry_link?.data;

    // Check if link is using market or product to force module fetch
    if (linkData && (linkData.market || linkData.product)) {
      needfetch = true;
    }

    return {
      icon: imageAdapter(item.avantage_entry_icon),
      title: item.advantage_entry_title,
      text: item.advantage_entry_description,
      link: $enhancedLinkSerializer(item.advantage_entry_link),
    };
  });

  // Fetch module again to have all the needed data to generate links
  if (needfetch) {
    const push = await $prismic.client.getByID(moduleId, {
      fetchLinks: routerLinks,
    });

    if (push && push?.data?.advantage_entries) {
      push.data.advantage_entries.forEach((entry, index) => {
        if (entry.advantage_entry_link) {
          listItems[index].link = $enhancedLinkSerializer(
            entry.advantage_entry_link
          );
        }
      });
    }
  }

  return listItems;
};

export default async (
  { primary },
  data,
  { $prismic, $enhancedLinkSerializer }
) => {
  const defaultModule = primary.advantage_module_default;
  const defaultModuleData = defaultModule?.data;

  if (!defaultModuleData) return null;

  const defaultModuleProps = {
    title: stripRichTextWrapperTag(defaultModuleData?.advantage_title),
    greyBackground: defaultModuleData?.advantage_grey_background,
    listItems: await getItems(
      defaultModuleData,
      defaultModule.id,
      $prismic,
      $enhancedLinkSerializer
    ),
  };

  return defaultModuleProps;
};
